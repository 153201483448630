import { IoSwapHorizontalOutline,IoRocketOutline } from "react-icons/io5";
import { IoIosPeople } from "react-icons/io";
import { BsStars,BsSliders } from "react-icons/bs";
import { AiFillDashboard } from "react-icons/ai";
import { FaShip,FaThumbsUp,FaRoute,FaCalendarDay,FaSearch,FaFacebookSquare,
  FaTwitterSquare,FaBars,FaPlayCircle,FaHubspot,FaSalesforce,FaGoogle,
  FaMicrosoft,FaAmazon } from "react-icons/fa";
import { FaArrowRightLong,FaInstagram,FaCircleCheck } from "react-icons/fa6";
import { MdClose } from "react-icons/md";
import { LuLayers } from "react-icons/lu";
import { TbUserScreen } from "react-icons/tb";
import { SiZoho } from "react-icons/si";

const IconMap = (iconName: any, classnames: any = undefined, iconStyle = {}, size = 20) => {
  // let size = 20;
  let icon = null;
  switch(iconName) {
    
    case 'FaAmazon': 
      icon = <FaAmazon className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaMicrosoft': 
      icon = <FaMicrosoft className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaGoogle': 
      icon = <FaGoogle className={classnames} size={size} style={iconStyle} />
    break;
    case 'SiZoho': 
      icon = <SiZoho className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaSalesforce': 
      icon = <FaSalesforce className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaHubspot': 
      icon = <FaHubspot className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaCircleCheck': 
      icon = <FaCircleCheck className={classnames} size={size} style={iconStyle} />
    break;
    case 'BsSliders': 
      icon = <BsSliders className={classnames} size={size} style={iconStyle} />
    break;
    case 'TbUserScreen': 
      icon = <TbUserScreen className={classnames} size={size} style={iconStyle} />
    break;
    case 'LuLayers': 
      icon = <LuLayers className={classnames} size={size} style={iconStyle} />
    break;
    case 'IoRocketOutline': 
      icon = <IoRocketOutline className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaPlayCircle': 
      icon = <FaPlayCircle className={classnames} size={size} style={iconStyle} />
    break;
    case 'MdClose': 
      icon = <MdClose className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaBars': 
      icon = <FaBars className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaInstagram': 
      icon = <FaInstagram className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaTwitterSquare': 
      icon = <FaTwitterSquare className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaFacebookSquare': 
      icon = <FaFacebookSquare className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaSearch': 
      icon = <FaSearch className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaArrowRightLong': 
      icon = <FaArrowRightLong className={classnames} size={size} style={iconStyle} />
    break;
    case 'IoIosPeople': 
      icon = <IoIosPeople className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaRoute': 
      icon = <FaRoute className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaCalendarDay': 
      icon = <FaCalendarDay className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaThumbsUp': 
      icon = <FaThumbsUp className={classnames} size={size} style={iconStyle} />
    break;
    case 'FaShip': 
      icon = <FaShip className={classnames} size={size} style={iconStyle} />
    break;
    case 'AiFillDashboard': 
      icon = <AiFillDashboard className={classnames} size={size} style={iconStyle} />
    break;
    case 'BsStars': 
      icon = <BsStars className={classnames} size={size} style={iconStyle} />
    break;
    case 'IoSwapHorizontalOutline': 
      icon = <IoSwapHorizontalOutline className={classnames} size={size} style={iconStyle} />
    break;
    
  }
  return icon;
};

export default IconMap;