import { useRef, useState } from 'react';
import { Logo } from '../assetMapping/ImgPath';
import ReCAPTCHA from 'react-google-recaptcha';
import ToastComponent from '../alerts/Toast';

interface ToastDetailsProperty {
  status: boolean,
  summary: string,
  detail: string
}

const url = "https://uat.sectorsift.com/app";
// const url = "http://localhost:8000";

export default function FreeLeadsGeneration() {
  const toastRef = useRef<any>(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
  });
  const [isSent, setIsSent] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [toastDetails, setToastDetails] = useState<ToastDetailsProperty>({
    status: false,
    summary: '',
    detail: ''
  });

  const handleChange = (e:any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCaptchaChange = (token:any) => {
    setCaptchaToken(token);
  };

  const validateCaptcha = () => {
    return fetch(`/app/api/validate/captcha`, { 
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({captcha_token: captchaToken})
    });
  }

  const submitData = async () => {
    const response = await fetch(`/app/api/marketing/leads`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...formData, captchaToken }), 
    });

    if (!response.ok) {
      console.log("response::", response);
    }

    const result = await response.json();


    toastRef.current.show({
      severity: result.status ? 'success' : 'error',
      summary: result.status ? 'Email Sent!' : 'Failed!',
      detail: result.message,
      life: 3000,
    });

    if(!result.status){
      setIsSent(false);
    }else{
      setCaptchaToken(null);
    }
  }

  const handleSubmit = async (e:any) => {
    e.preventDefault();

    if (!captchaToken) {
      toastRef.current.show({
        severity: 'error',
        summary: 'CAPTCHA Error!',
        detail: "Please complete the CAPTCHA",
        life: 3000,
      });
      return;
    }

    setIsSent(true);
    try {
      validateCaptcha().then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        submitData();
      })
      .catch(error => {
        console.error('Error:', error);
      });
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  return (
    <>
      <ToastComponent ref={toastRef} />
      <div className='flex flex-row mt-[3rem] mx-[2%] xl:mx-[5%]'>
        <div 
          className='rounded rounded-[24px] h-[30rem] h-full xl:h-[22rem]'
          style={{
            width: '100%', 
            color: 'white',
            background: `#0E1833`,
            backgroundSize: 'cover',
            backgroundPosition: 'center', 
            backgroundRepeat: 'no-repeat',
        }}>
          <div className='flex justify-center grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-5 p-10 lg:p-[20px] h-full'>
            <div className='flex flex-col justify-center items-center'>
              <img alt='Logo' src={Logo}/>
              <h2 className='mt-5 text-[25px] text-center md:text-[30px] text-white font-regular'>Test it out yourself!</h2>
              <h2 className='text-[18px] text-center md:text-[20px] text-white font-light'>Our AI tools will create custom emails based on what it finds about you on the web.</h2>
            </div>
            <div className='flex flex-col justify-center'>
              <div className='mt-5 justify-center lg:justify-start'>
                <form className='px-5' onSubmit={handleSubmit}>
                  <div >
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      className="w-full rounded rounded-[8px] p-3 my-1 text-black"
                      placeholder="Full name"
                    />
                  </div>
                  <div>
                    <input
                      type="text"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      className="w-full rounded rounded-[8px] p-3 my-1 text-black"
                      placeholder="Email"
                    />
                  </div>
                  <ReCAPTCHA
                    sitekey="6LdNrHUqAAAAADSd3wk0gO3RuvCznRwrttvthDnC"
                    onChange={handleCaptchaChange}
                  />
                  <button
                    disabled={isSent}
                    type="submit"
                    className="p-3 bg-[#397BFF] w-full rounded rounded-[8px] mt-2 flex justify-center items-center"
                  >
                    
                    <span className="font-regular text-[14px] md:text-[20px]">
                      {
                        isSent ?
                          "Email sent!" 
                        :
                          "Send me an email!"
                      }
                      
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
} 