import React, { createContext, useContext, useRef,useState, ReactNode,useEffect } from 'react';
import { Toast } from 'primereact/toast';

type ToastData = {
  severity: 'success' | 'info' | 'warn' | 'error';
  summary: string;
  detail: string;
  sticky?: boolean;
  life?: number;
  loading?: boolean;
};

type ToastContextType = {
  showToast: (data: ToastData) => void;
  clearToast?: any;
};

const ToastContext = createContext<ToastContextType | undefined>(undefined);

const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const toastRef = useRef<Toast | null>(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const showToast = (data: ToastData) => {
    if (toastRef.current) {
      const options = {
        severity: data.severity,
        summary: data.summary,
        detail: data.detail,
        ...(data.life && data.sticky ? { life: data.life }: { life: 3000 }),
        sticky: data.sticky || false,
        ...(data.loading && { icon: 'pi pi-spin pi-spinner' }),
        ZIndexUtils: 9999
      };
      toastRef.current.show(options);
    }
  };

  const clearToast = () => {
    if (toastRef.current) {
      toastRef?.current.clear();
    }
  }
  const toastPt = {
    content: {
      className: 'border-none flex items-center', 
    },
    root: {
      style: {
        right: isSmallScreen ? '4px' : '20px',
      },
    }
  };
  useEffect(() => {
    const updateScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 640); 
    };

    updateScreenSize(); 
    window.addEventListener('resize', updateScreenSize);

    return () => window.removeEventListener('resize', updateScreenSize); 
  }, []);
  return (
    <ToastContext.Provider value={{ showToast, clearToast }}>
      {children}
      <Toast pt={toastPt} ref={toastRef} />
    </ToastContext.Provider>
  );
};

const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export { ToastProvider, useToast };