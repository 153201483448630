import React, { useEffect, useState,useRef } from 'react';
import { BgSec1,DashboardPreview, VidFrame, VidPreview, IconGoogle, IconLeads, IconGears } from '../../components/assetMapping/ImgPath';
// import Inputs from '../../components/forms/Inputs';
import IconMap from '../../components/assetMapping/IconMap';
import { Card } from 'primereact/card';
import { InputSwitch } from "primereact/inputswitch";
import { Badge } from 'primereact/badge';
import moment from "moment";
import { Dialog } from 'primereact/dialog';
import FreeLeadsGeneration from '../../components/forms/FreeLeadsGeneration';

const useInView = (options: IntersectionObserverInit) => {
  const [inView, setInView] = useState(false);
  const ref = useRef<any>(null);
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setInView(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return { ref, inView };
};


const HomePage: React.FC<any> = ({ setLoadingState }) => {
  // const secondSectionRef = useRef<HTMLDivElement>(null);
  const { ref: firstSectionRef, inView: firstSectionInView } = useInView({ threshold: 0.1 });
  const { ref: secondSectionRef, inView: secondSectionInView } = useInView({ threshold: 0.1 });
  const { ref: thirdSectionRef, inView: thirdSectionInView } = useInView({ threshold: 0.1 });
  const { ref: fourthSectionRef, inView: fourthSectionInView } = useInView({ threshold: 0.1 });
  const { ref: fifthSectionRef, inView: fifthSectionInView } = useInView({ threshold: 0.1 });
  const { ref: sixthSectionRef, inView: sixthSectionInView } = useInView({ threshold: 0.1 });
  // const { ref: seventhSectionRef, inView: seventSectionInView } = useInView({ threshold: 0.1 });
  const [visible, setVisible] = useState(false);

  const [userDetails, setUserDetails] = useState<any>({
    email: "",
    first_login: 1,
    notification_settings: null
  });

  const [isAnnually, setIsAnnually] = useState(false);

  const handleLearnMoreClick = () => {
    if (secondSectionRef.current) {
      secondSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const items: any[] = [
    {
      icon: IconMap('IoRocketOutline','text-[#3A70DB] mb-3', undefined, 40),
      title: 'Step 1',
      description: 'Share insights about your business, your unique offerings, and the characteristics of your ideal customer to foster meaningful engagement.'
    },
    {
      icon: IconMap('LuLayers', 'text-[#EF6A40] mb-3', undefined, 40),
      title: 'Step 2',
      description: 'Our A.I. initiates the process of crafting your personalized profile, an enticing pitch, and a compelling call to action.'
    },
    {
      icon: IconMap('TbUserScreen', 'text-[#28AF6E] mb-3', undefined, 40),
      title: 'Step 3',
      description: 'We pinpoint an extensive pool of leads that can be effectively engaged through personalized messages.'
    },
    {
      icon: IconMap('BsSliders', 'text-[#EC4DC9] mb-3', undefined, 40),
      title: 'Step 4',
      description: 'You can customize the messaging or allow our platform to directly contact your potential leads.'
    },
  ];
  
  const subscriptionData = [
    {
      Annual: false,
      data: [
        {
          title: "Free",
          price: 0,
          description: "Ideal to try out SectorSift sales pack.",
          features: [
            "25 credits",
            "1 Search Agent",
            "No Automatic Sending",
          ],
          btn_text: "Try it for free"
        },
        {
          title: "Start-up",
          price: 112.5,
          description: "All the core tools needed to set up steady sales.",
          features: [
            "250 Credits",
            "3 Search Agents",
            "Auto Send",
          ],
          btn_text: "Get Started"
        },
        {
          title: "Business",
          price: 199.00,
          description: "Business choice for incredible sales and business opportunities.",
          features: [
            "1000 credits",
            "10 Search Agents",
            "Auto Send and Follow-up",
            "1 Custom Agent",
          ],
          btn_text: "Get Started"
        },
        {
          title: "Business Plus",
          price: 750.00,
          description: "Email & LinkedIn outreach set and managed for you.",
          features: [
            "5000 credits",
            "50 Agents",
            "Auto Send and Follow-up",
            "Lead Monitoring",
            "5 Custom Agents",
          ],
          btn_text: "Get Started "
        },
      ]
    },
    {
      Annual: true,
      data: [
        {
          title: "Start-up",
          price: 1080.00,
          description: "All the core tools needed to set up steady sales.",
          features: [
            "3000 Credits",
            "3 Search Agents",
            "Auto Send",
          ],
          btn_text: "Get Started"
        },
        {
          title: "Business",
          price: 1920.00,
          description: "Business choice for incredible sales and business opportunities.",
          features: [
            "12000 credits",
            "10 Search Agents",
            "Auto Send and Follow-up",
            "1 Custom Agent",
          ],
          btn_text: "Get Started"
        },
        {
          title: "Business Plus",
          price: 7200.00,
          description: "Email & LinkedIn outreach set and managed for you.",
          features: [
            "60000 credits",
            "50 Agents",
            "Auto Send and Follow-up",
            "Lead Monitoring",
            "5 Custom Agents",
          ],
          btn_text: "Get Started"
        },
      ]
    }
  ];

  const doughnutClass:any = {
    "Free": "doughnut-0",
    "Start-up": "doughnut-1",
    "Business": "doughnut-2",
    "Business Plus": "doughnut-3",
  };
  
  return (
    <>
      <section
        ref={firstSectionRef}
        className={`flex flex-col overflow-hidden transition-opacity duration-1000 ease-out ${firstSectionInView ? 'opacity-100' : 'opacity-0'} lg:h-[100vh] md:h-[100%] pb-[150px] lg:pb-[0px] lx:pb-[0px] 2xl:pb-[0px]`}
        style={{
          // height: '100vh', 
          width: '100%', 
          color: 'white',
          background: `#0E1833 url(${BgSec1})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center', 
          backgroundRepeat: 'no-repeat',
        }}
      >
        {/* <div className='flex flex-col items-center md:mx-[20%] mt-[8rem] lg:mt-[8rem]'>
          
        </div> */}
        <div className='flex flex-col justify-center items-center md:mx-[20%] mt-[8rem] lg:mt-[8rem] h-[100%]'>
          <h1 className='text-[20px] sm:text-[30px] md:text-[40px] font-medium text-center md:leading-[50px]'>#1 Platform to Find and Grow Your Business</h1>
          <h1 className='text-[20px] sm:text-[30px] md:text-[40px] font-medium text-center md:leading-[50px]'>with Any Company in the World</h1>
          <h4 className='text-[12px] sm:text-[15px] md:text-[24px] mt-7 md:mt-5 font-[200] text-center w-[80%] md:w-full'>AI-driven engagement platform designed to find the right partners in a cost effective way </h4>
          <div className='flex mt-10 md:mt-5 w-full justify-center'>
            <a href='https://login.sectorsift.com/signup' target="_blank" className='p-4 bg-[#397BFF] rounded rounded-[8px] mr-5'><span className='font-regular text-[20px]'>Start Free Trial</span></a>
            <button className='group p-4 border border-white rounded rounded-[8px] hover:bg-white transition duration-300 ease-in-out'
              onClick={handleLearnMoreClick}>
              <span className='flex flex-row items-center font-regular text-[14px] md:text-[20px] group-hover:text-[#397BFF]'>Learn More {IconMap('FaPlayCircle','ml-2')}</span>
            </button>
          </div>
          {/* <img alt='SectorSift Dashboard Preview' width={`1000`} src={DashboardPreview}></img> */}
          <FreeLeadsGeneration />
        </div>
      </section>

      <section className='' ref={secondSectionRef}>
          
      <div className='flex flex-col items-center'>
        <div className='relative w-[90%] sm:w-[80%] md:w-[70%] lg:w-[60%] pb-[50%] sm:pb-[50%] md:pb-[40%] lg:pb-[28%] mt-[3rem] sm:mt-[4rem] md:mt-[5rem]' style={{ height: 0 }}>
          <iframe 
            src="https://www.youtube.com/embed/0UaIfn-jpHE" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
            className='absolute top-0 left-0 w-full h-full'>
          </iframe>
        </div>
      </div>

        <div className='flex flex-col items-center'>
          <h1 className={`text-[#546E7A] text-[30px] md:text-[40px] text-center font-[500] mt-[3rem] transition-transform duration-1000 ease-in-out transform ${secondSectionInView ? 'translate-y-0' : 'translate-y-10'}`}>
            How <span className='font-poppins'>SectorSift</span> does your Lead Generation
          </h1>
          <h4 className={`text-[12px] sm:text-[15px] md:text-[20px] mt-2 mx-[5%] font-[200] text-center transition-transform duration-1000 ease-in-out transform  ${secondSectionInView ? 'translate-y-0' : 'translate-y-10'}`}>We find the best leads for your business using our advanced algorithm and A.I. filters.</h4>
          <h4 className={`text-[12px] sm:text-[15px] md:text-[20px] mx-[5%] font-[200] text-center transition-transform duration-1000 ease-in-out transform  ${secondSectionInView ? 'translate-y-0' : 'translate-y-10'}`}>Our technology delivers high-quality, tailored leads, letting you focus on growing your business.</h4>
        </div>

        <div className='mx-[10%] flex flex-col md:flex-row justify-center  items-center mt-[2rem]'>
          <div className='w-full'>
            <div className='flex justify-center grid grid-cols-1 lg:grid-cols-2 gap-5'>
              {items.map((item, index) => (
                <Card key={index} className="md:w-25rem shadow-none border rounded rounded-[24px] transition duration-300 ease-in-out hover:shadow-xl hover:cursor-pointer ">
                  <div className='flex flex-col items-center p-4'>
                    {item.icon}
                    <h3 className='text-[18px] text-[#546E7A] font-semibold mb-3'>{item.title}</h3>
                    <p className='text-center text-[16px] text-[#333333] font-light'>{item.description}</p>
                  </div>
                </Card> 
              ))}
              
            </div>
          </div>
          <div className='md:p-[4rem] md:w-[70%]'>
            <h2 className='mt-[2rem] md:mt-0 text-center lg:text-start text-[#546E7A] text-[20px] md:text-[30px] font-[300] mb-3 leading-[1.2]'>Our mission is to revolutionize B2B sales by making them easier, more affordable, and smarter for businesses around the world.</h2>
            <p className='text-center lg:text-start text-[14px] md:text-[18px] text-[#333333] font-[300] leading-[32px] leading-normal'>With <span className='font-poppins'>SectorSift</span>, you can have a powerful lead-generating sales team in just 4 simple steps!</p>
          
            <div className='flex justify-center lg:justify-start'><a href='https://login.sectorsift.com/signup' target="_blank" className='p-3 bg-[#397BFF] rounded rounded-[8px] mt-8'><span className='font-regular text-[20px] text-white'>Get Started</span></a></div>
          </div>
        </div>
      </section>

      <section ref={thirdSectionRef}> {/* Ensures the section takes full viewport height */}
        <div className='flex flex-row mt-[3rem] h-[50rem] lg:h-[42rem] mx-[10%]'>
          <div 
            className='rounded rounded-[24px] h-[30rem] md:h-[40rem] lg:h-[22rem]'
            style={{
              width: '100%', 
              color: 'white',
              background: `#0E1833 url(${VidFrame})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center', 
              backgroundRepeat: 'no-repeat',
          }}>
            <div className='flex justify-center grid grid-cols-1 lg:grid-cols-2 gap-5 p-5 h-full'>
              <div className='flex justify-center items-center'><img alt='Vid Preview' src={VidPreview}/></div>
              <div className='flex flex-col justify-center'>
                <h2 className='text-[30px] md:text-[40px] text-white font-regular'>See how easy it is</h2>
                <h2 className='text-[18px] md:text-[20px] text-white font-light'>We designed SectorSift to be user-friendly for anyone aiming to boost their lead funnel.</h2>
                <div className='mt-5 flex justify-center lg:justify-start'>
                  <button className='p-3 bg-[#397BFF] rounded rounded-[8px] mt-2'
                    onClick={()=>setVisible(true)}>
                    <span className='flex flex-row items-center font-regular text-[14px] md:text-[20px]'>
                      Watch our demo video {IconMap('FaPlayCircle','ml-2')}
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className='flex justify-center grid grid-cols-1 lg:grid-cols-3 gap-5 p-5 h-full mt-[3rem]'>
              <div className='flex flex-col items-center '>
                <div>
                  <img alt='Google Icon' width='72' height='72' src={IconGoogle}/>
                </div>
                <h2 className='text-[20px] text-[#546E7A] font-medium mt-3'>Powered by Google</h2>
                <p className='text-center text-[16px] text-[#333333] font-light mt-2 '>Leveraging the latest Google Search technologies, we uncover businesses that typically fly under the radar.</p>
              </div>
              <div className='flex flex-col items-center'>
                <div>
                  <img alt='Leads Icon' width='72' height='72' src={IconLeads}/>
                </div>
                <h2 className='text-[20px] text-[#546E7A] font-medium mt-3'>Access to over 300M+ Companies</h2>
                <p className='text-center text-[16px] text-[#333333] font-light mt-2'>We augment our discovery process with access to over 300 million companies and over 1B+ Contacts worldwide.</p>
              </div>
              <div className='flex flex-col items-center'>
                <div>
                  <img alt='Gears Icon' width='72' height='72' src={IconGears}/>
                </div>
                <h2 className='text-[20px] text-[#546E7A] font-medium mt-3'>Fully Integratable</h2>
                <p className='text-center text-[16px] text-[#333333] font-light mt-2'>Emails to your leads are sent directly from your account, whether it's Google, Office365, or SMTP.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='mt-[25rem] md:mt-[32rem] lg:mt-[0rem] py-[3rem]' ref={fourthSectionRef}>
        <div className='flex flex-col items-center'>
          <h1 className={`text-center text-[#546E7A] text-[40px] font-[500] leading-[2.4rem] transition-transform duration-1000 ease-in-out transform ${fourthSectionInView ? 'translate-y-0' : 'translate-y-10'}`}>
            Easily connect to your favorite CRM
          </h1>
          <div className='flex  justify-center mt-[3rem] grid grid-cols-3  gap-10 max-w-none'>
            <div>
              {IconMap('FaHubspot','text-[#397BFF]',undefined,80)}
            </div>
            <div>
              {IconMap('FaSalesforce','text-[#397BFF]',undefined,80)}
            </div>
            <div>
              {IconMap('SiZoho','text-[#397BFF]',undefined,80)}
            </div>
          </div>
        </div>
        <div className='flex flex-col items-center mt-5'>
          <h1 className={`text-center text-[#546E7A] text-[20px] font-[400] leading-[2rem] transition-transform duration-1000 ease-in-out transform ${fourthSectionInView ? 'translate-y-0' : 'translate-y-10'}`}>
            See all integrations
          </h1>
          <div className='flex justify-center mt-[1rem] grid grid-cols-3 gap-10 max-w-none'>
            <div>
              {IconMap('FaGoogle','text-[#397BFF]',undefined,30)}
            </div>
            <div>
              {IconMap('FaMicrosoft','text-[#397BFF]',undefined,30)}
            </div>
            <div>
              {IconMap('FaAmazon','text-[#397BFF]',undefined,30)}
            </div>
          </div>
        </div>
      </section>
      <section className='bg-[#F4F8FB] mt-[0rem]' ref={fifthSectionRef}>
        <div className='flex flex-col items-center'>
          <h1 className={`text-center text-[#546E7A] text-[40px] font-[500] mt-[3rem] leading-[2.4rem] transition-transform duration-1000 ease-in-out transform ${fifthSectionInView ? 'translate-y-0' : 'translate-y-10'}`}>
            We have a plan for every business
          </h1>
          <h4 className={`text-[12px] text-[#333333] sm:text-[15px] md:text-[20px] mt-2 font-[200] text-center transition-transform duration-1000 ease-in-out transform  ${fifthSectionInView ? 'translate-y-0' : 'translate-y-10'}`}>Switch to yearly pricing for the best deal!</h4>
          <div className={`mt-[2rem] flex `}>
            <span className={`mr-2 ${isAnnually? 'text-[#999999]':'text-[#333333]'}`}>Monthly</span> 
            <InputSwitch checked={isAnnually} onChange={(e) => setIsAnnually(e.value)} />
            <span className={`mx-2 ${isAnnually? 'text-[#333333]':'text-[#999999]'}`}>Annually</span> 
            <Badge value="Save 20%" className='bg-[#3FC7A6]'></Badge>
          </div>
        </div>
        <div className={`flex justify-center mt-10 mx-[5%] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 max-w-none`}>
          {subscriptionData
            .filter(subscription => subscription.Annual === isAnnually)
            .flatMap(subscriptionGroup => subscriptionGroup.data.map((subscription, index) => (
              <div key={index} 
                  className={`w-full w-[300px] relative p-4 rounded-2xl shadow-sm flex flex-col drop-shadow-xl 
                  ${subscriptionGroup.data.length < 4 ? 'lg:mx-[50%]' : ''} ${index === subscriptionGroup.data.length - 1 ? "bg-gradient-to-b from-[#E7E5FF] to-[#FFF]" : "bg-white"}`}>
                <div className="relative w-10 h-10">
                  <div className="absolute inset-0 flex justify-center items-center">
                    <div className="w-10 h-10 rounded-full bg-[#E7E7E7]"></div>
                  </div>
                  <div className="absolute inset-0 flex justify-center items-center">
                    <div className={doughnutClass[subscription.title] || "default-doughnut"}></div>
                  </div>
                  <div className="absolute inset-0 flex justify-center items-center">
                    <div className={`w-4 h-4 rounded-full ${index === subscriptionGroup.data.length - 1 ? '' : 'bg-white'}`}></div>
                  </div>
                </div>
                <div className="flex-1">
                  <h3 className="text-3xl sm:text-[32px] leading-auto font-bold">{subscription.title}</h3>
                  <p className="text-xl sm:text-[16px] leading-[24px] font-regular">{subscription.description}</p>
                  <p className="mt-4 flex items-baseline mb-4">
                    {subscription.price != null ?
                      <>
                        <span className={`text-5xl sm:text-[32px] font-extrabold tracking-tight`}>€{subscription.price % 1 === 0 ? subscription.price.toFixed(0) : subscription.price.toFixed(2)}</span>
                        <span className={`ml-1 text-3xl sm:text-[32px] font-regular text-[#999999]`}>/{isAnnually ? 'year' : 'month'}</span>
                      </>
                      :
                      <><h3 className="text-3xl sm:text-[32px] leading-auto">Talk to us!</h3></>}
                  </p>
                  <ul className="pt-6 space-y-2 border-t border-[#E7E7E7]">
                    {subscription.features.map((feature, featureIndex) => (
                      <li key={featureIndex} className="flex mt-0">
                        {IconMap("FaCircleCheck", 'text-[#A1B8F3]')}
                        <span className="ml-3 ">{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
                <a href='https://login.sectorsift.com/signup' target="_blank" className="mt-4 text-emerald-700 w-full py-3 px-6 border border-[2px] border-[#397BFF] rounded-[8px] text-center font-medium bg-[#397BFF] text-white">
                  {subscription.btn_text}
                </a>
              </div>
            )))}
        </div>
      </section>

      <section className='h-[360px] bg-[#F4F8FB] lg:mt-[0rem] flex flex-col justify-center items-center' ref={sixthSectionRef}>
        <div className='flex flex-col justify-center items-center'>
          <h1 className={`text-center text-[#546E7A] text-[20px] md:text-[40px] font-[500] leading-[1.5rem] transition-transform duration-1000 ease-in-out transform ${sixthSectionInView ? 'translate-y-0' : 'translate-y-10'}`}>
            Need more features and customizations?
          </h1>
          <h4 className={`px-[5%] mt-3 text-[14px] text-[#333333] md:text-[18px] leading-[1rem] md:leading-[1.5rem] font-[200] text-center transition-transform duration-1000 ease-in-out transform  ${sixthSectionInView ? 'translate-y-0' : 'translate-y--10'}`}>
            We can customize almost every aspect of your B2B lead Generation. Talk to us today to find out
          </h4>
        </div>
        <a href='mailto:sales@sectorsift.com' className='group p-4 mt-4 border border-[#397BFF] rounded rounded-[8px] hover:bg-[#397BFF] transition duration-300 ease-in-out'>
          <span className='flex flex-row items-center font-regular text-[20px] text-[#397BFF] group-hover:text-white'>Talk to us </span>
        </a>
      </section>

      <Dialog 
        header={
          <div>
            <h1 className="text-3xl font-bold">Video Guide</h1>
            <p className="text-sm text-gray-500">
              Video guide to help you get started
            </p>
          </div>
        }
        pt={{
          header: { className: 'border-b-[1px] border-gray shadow-sm' },
        }}
        visible={visible} 
        onHide={() => { setVisible(false); }}
        style={{ width: '70vw' }} 
        breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        // closable={false}
        // dismissableMask={true}
        >
        {/* p-4 border-b-[1px] border-gray shadow-sm w-full bg-white z-[400] mb-[2rem] */}
        <div className='mt-5' style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
          {/* <iframe 
            src="https://www.youtube.com/embed/0UaIfn-jpHE" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen
            style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}>
          </iframe> */}
          <iframe
            src="https://demo.arcade.software/pQkqZckDfYP7J038QTkv?embed&embed_mobile=tab&embed_desktop=inline&show_copy_link=true"
            title="SectorSift"
            frameBorder="0"
            loading="lazy"
            allowFullScreen
            allow="clipboard-write"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              colorScheme: 'light',
            }}
          />
        </div>
        
      </Dialog>
    </>
  );
};

export default HomePage;
