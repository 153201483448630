import React,{useState} from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// PAGES IMPORTS
import HomePage from "../pages/home/HomePage";

const AppRouter: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage setLoadingState={setIsLoading} />} />
      </Routes>
    </Router>
  )
};

export default AppRouter;