import { useEffect, useRef, useState } from "react";
import { Logo, LogoSmall } from '../assetMapping/ImgPath';
import IconMap from "../assetMapping/IconMap";

const Footer: React.FC<any> = ({ })=>{

  return(
    <>
      {/* <section className="h-[100px]">
        <img width={104} src={Logo}/>
      </section> */}
      <footer className="h-[150px] md:h-[100px] text-center py-4 bg-[#0E1833] flex flex-col justify-center items-center">

        <div className="flex flex-col md:flex-row mx-3 md:mx-0">
          <div className="flex justify-center md:mx-3 flex items-center mb-2 md:mb-0"><div><img width={104} src={Logo}/></div></div>
          <p className="md:flex md:items-center text-gray-600 text-[13px] text-center md:text-start ">
            © 2024 SectorSift. All Rights Reserved. 
            <a href="https://login.sectorsift.com/terms-and-conditions" target="_blank" className="text-[rgb(195,195,195)] underline ml-1">Terms of Service</a> and 
            <a href="https://login.sectorsift.com/privacy-policy" target="_blank" className="text-[rgb(195,195,195)] underline ml-1">Privacy Policy</a>.
          </p>
        </div>

        <div className="flex justify-center ">
          <p className="text-[10px] text-center text-gray-600 mt-2 md:mt-0">Make a suggestion or contact us at <a className="text-[#8D2CFE] hover:underline" href="mailto:support@sectorsift.com"> support@sectorsift.com</a></p>
        </div>
      </footer>
    </>
  )
  
}


export default Footer;