import React,{useState} from "react";
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";

import AppRouter from "../router/AppRouter";

const Main: React.FC<any> = ({setLoadingState}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  return (
    <>
    
    <Header/>

    <AppRouter/>
    
    <Footer/>
    </>
  )
};

export default Main;