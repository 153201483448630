import React from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './app/pages/Main';

import { twMerge } from 'tailwind-merge';
import 'primereact/resources/themes/saga-blue/theme.css'; 
import 'primereact/resources/primereact.min.css'; 
import 'primeicons/primeicons.css';
import { PrimeReactProvider } from 'primereact/api';
import { ToastProvider } from './app/contexts/ToastContext';

function App() {
  return (
  //  <Main/>
  <PrimeReactProvider value={{ ripple: true, unstyled: false, pt: {}, ptOptions: { mergeSections: true, mergeProps: true, classNameMergeFunction: twMerge } }}>
    <ToastProvider>
      <Main/>
    </ToastProvider>
  </PrimeReactProvider>
  );
}

export default App;
