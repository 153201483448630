import React, { useState, useEffect } from 'react';
import { Logo, LogoSmall } from '../assetMapping/ImgPath';
import IconMap from '../assetMapping/IconMap';

const Header: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50); 
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
    
    <header className={`fixed top-0 left-0 w-full p-4 transition-colors duration-300 z-10  ${isScrolled || menuOpen ? 'bg-[#0E1833] text-black' : 'bg-transparent text-white border-b border-white/10'}`}>
      <div className='mx-[4%] flex justify-between items-center'>
        {/* <img className='hidden lg:block' alt='Desktop Logo'  src={LogoAlt} width={230}/> */}
        <img className='block lg:hidden' alt='Mobile Logo' src={LogoSmall} width={24}/>
        <img className='hidden lg:block' alt="Desktop Logo" src={Logo} width={200}/>
        
        <nav>
          <button  className='flex space-x-4 block lg:hidden border border-white bg-white bg-opacity-[15%] text-white rounded p-2' onClick={() => setMenuOpen(prevState => !prevState)}>{menuOpen? IconMap('MdClose'): IconMap('FaBars')}</button>
          <ul className='flex space-x-4 hidden lg:flex'>
            <li className='pr-4 flex items-center'><a href='https://login.sectorsift.com/' target="_blank" className={`hover:underline text-white text-[20px] font-regular`}>Login</a></li>
            <li><a href='https://login.sectorsift.com/signup' target="_blank" className='py-2 px-4 bg-[#397BFF] rounded rounded-[8px] text-white'>Signup + Free Trial</a></li>
          </ul>
        </nav>
      </div>
    </header>
    <div className='relative'>
      {
        menuOpen && 
        <ul className='fixed flex flex-col items-center w-full bg-[#0E1833] p-4 mt-[4rem] z-10 lg:hidden'>
          <li className='pr-4 flex items-center'><a href="https://login.sectorsift.com/" target="_blank" className={`hover:underline text-white text-[20px] font-regular mb-4`}>Login</a></li>
          <li><a href='https://login.sectorsift.com/signup' target="_blank" className='py-2 px-4 bg-[#397BFF] rounded rounded-[8px] text-white'>Signup + Free Trial</a></li>
        </ul>
      }
    </div>
    </>
    
  );
};

export default Header;
